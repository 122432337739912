
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;800;900&display=swap');
:root {
  --app-font-family: 'Roboto', sans-serif; /* Default font-family */
}
*{
  font-family: var(--app-font-family);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 500;
}
body {
  margin: 0;
  padding : 0 ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a{
  text-decoration: none;
}
ul{
  list-style: none;
  padding-left : 0 ;
  /* padding: 0; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

} */
/* style scroll bar  */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height : 5px ;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #DCDCDC; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #afafaf; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}
